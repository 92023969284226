import React, { Component } from 'react'
import { Input, message } from 'antd'
import moment from 'moment'
import { massCntentHOC } from '../massContext'
import './index.less'

const AuthComponent = Loader.loadBusinessComponent('AuthComponent')
const IconFont = Loader.loadBaseComponent('IconFont')
const ImageView = Loader.loadBaseComponent('ImageView')
const NoData = Loader.loadBusinessComponent('NoData')
const ConfirmComponent = Loader.loadBusinessComponent('ConfirmComponent')
const PlayComponent = Loader.loadBusinessComponent('PlayComponent')
const scrDefault = '/resource/image/user-default.svg'

@massCntentHOC
class MassContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '', // 处理意见
      tabCode: '0',
      inputVisible: true,
      updateValue: '', // 更新意见
      massVisible: false // 处理模态框
    }
    this.changTimer = null
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeMass !== this.props.activeMass) {
      if (nextProps.activeMass) {
        const commentList = nextProps.activeMass.commentList
        let updateValue = ''
        if (Array.isArray(commentList) && commentList.length) {
          updateValue = commentList[0].comment
          this.setState({
            updateValue,
            keyRandom: Math.random(),
            inputVisible: true
          })
        }
      }
    }
  }
  /**
   * input修改
   */
  changeDetail = (event, type) => {
    const value = event.target.value
    if (value.length > 200) {
      clearTimeout(this.changTimer)
      this.changTimer = setTimeout(() => message.info('最大长度不超过200'), 500)
      return
    }
    if (type === 0) {
      this.setState({
        value: event.target.value
      })
    } else {
      this.setState({
        updateValue: event.target.value
      })
    }
  }

  /**
   * 提交处理意见
   */
  handleMass = () => {
    const { value } = this.state
    const { handleMass } = this.props
    handleMass && handleMass(value)
    this.setState({
      value: '',
      massVisible: false
    })
  }

  changeInputVisible = type => {
    const { commentList = [] } = this.props.activeMass
    this.setState({
      inputVisible: type,
      updateValue: commentList[0].comment
    })
  }

  updateComment = () => {
    const { updateValue } = this.state

    const { updateComment, activeMass = {} } = this.props
    const commentList = activeMass.commentList
    const id = (Array.isArray(commentList) && commentList.length && commentList[0].id) || undefined
    updateComment && updateComment({ id, comment: updateValue })
  }

  handModal = parms => {
    this.setState({
      massVisible: parms
    })
  }

  render() {
    const { activeMass = {} } = this.props
    const { value, inputVisible, updateValue, massVisible } = this.state
    const { uploadUserId, uploadTime, address, content, attachments = [], status, commentList = [], userAvatar, realName } = activeMass
    if (!Object.keys(activeMass).length) {
      return <NoData />
    }
    let src = ''
    if (attachments.length) {
      if (attachments[0].type === '2') {
        src = attachments[0].videoThumbnailUrl
      } else {
        src = attachments[0].url
      }
    }
    return (
      <div className="mass-content-bg">
        <div className="mass-content">
          <div className="mass-content-header">
            <div className="header-info">
              <div className="header-info-box">
                <ImageView className="info-img" defaultSrc={scrDefault} src={userAvatar} hasErrorImageStyle={false} />
              </div>
              <span className="info-text">{realName || ''}</span>
            </div>
            {status === '0' ? (
              <AuthComponent actionName="epidemicDel">
                <div className="header-del">
                  <Input
                    placeholder="请输入线索处理意见, 最大长度不超过200"
                    value={value}
                    allowClear={true}
                    onChange={e => this.changeDetail(e, 0)}
                    maxLength={200}
                  />
                  <div className="del-button" onClick={this.handModal.bind(this, true)}>
                    <IconFont type="icon-S_Bar_Sign" theme="outlined" />
                    处理线索
                  </div>
                </div>
              </AuthComponent>
            ) : (
              <div className="header-text">
                {inputVisible ? (
                  <React.Fragment>
                    <div className="header-text-comment">{commentList.length ? commentList[0].comment : ''}</div>
                    <AuthComponent actionName="epidemicDel">
                      <IconFont
                        type="icon-S_Edit_Edit"
                        className="comment-icon"
                        theme="outlined"
                        onClick={this.changeInputVisible.bind(this, false)}
                      />
                    </AuthComponent>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Input
                      className="header-text-input"
                      value={updateValue}
                      allowClear={true}
                      onChange={e => this.changeDetail(e, 1)}
                      maxLength={200}
                    />
                    <IconFont type="icon-S_Photo_MarkYes" className="mass-icon" theme="outlined" onClick={this.updateComment} />
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
          <div className="mass-content-content">
            <div className="content-info">
              <div className="info-title">
                <div className="title">时间：</div>
                <span className="info">{uploadTime ? moment(+uploadTime).format('YYYY年MM月DD日 HH:mm:ss') : ''}</span>
              </div>
              <div className="info-title">
                <div className="title">地址：</div>
                <span className="info">{address || ''}</span>
              </div>
              <div className="info-title">
                <div className="title">内容：</div>
                <span className="info">{content || ''}</span>
              </div>
            </div>

            <div className="content-image-moive">
              {attachments &&
                attachments.map(v => {
                  if (v.type === '2') {
                    return (
                      <div className="info-img-box">
                        <PlayComponent fileData={{ file: v.url }} hasLiving={false} isNormal={true} isLiving={false} autoplay={false} />
                      </div>
                    )
                  } else {
                    return (
                      <div className="info-img-box">
                        <ImageView className="info-img" src={v.url} />
                      </div>
                    )
                  }
                })}
            </div>
            {status === '1' && <div className="deal-rotate" />}
          </div>
        </div>
        <ConfirmComponent
          visible={massVisible}
          title="处理线索"
          icon="icon-S_Bar_Question"
          width={320}
          className="mass-modal"
          onCancel={this.handModal.bind(this, false)}
          onOk={this.handleMass}
        >
          <div className="text">是否标记为已处理</div>
        </ConfirmComponent>
      </div>
    )
  }
}
export default MassContent

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MassList from "./components/massList";
import MassContent from "./components/massContent";
import { massCntentHOC } from "./components/massContext";
import "./style/view.less";

const TwoColumnLayout = Loader.loadBaseComponent("TwoColumnLayout");
const massTabs = [
  { value: 0, label: "新冠疫情线索" },
  
];

@withRouter
@massCntentHOC
class MassView extends Component {
  mergeSearchData = () => {
    const { mergeSearchData, getActiveKey } = this.props;
    let option = {
      address: undefined,
      content: undefined,
      endTime: undefined,
      startTime: undefined,
      status: undefined,
      type: undefined,
      limit: 24,
      offset: 0
    };
    mergeSearchData && mergeSearchData(option);
  };
  render() {
    const { searchData } = this.props;
    return (
      <TwoColumnLayout
        className="mass-control-view"
        title="疫情防控"
        renderLeft={<MassList />}
        tabs={massTabs}
        titleExtra={
          <div className="mass-control-total" onClick={this.mergeSearchData}>
            重置
          </div>
        }
        getActiveKey={this.props.getActiveKey}
        defaultActiveKey={searchData.type ? searchData.type : 0}
      >
        <MassContent key={this.props.activeMass ? this.props.activeMass.id : Math.random()} />
        }/>
      </TwoColumnLayout>
    );
  }
}

export default MassView;
